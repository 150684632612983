import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import DevTitle from '../images/devonshire_title.png';
import Link from "@material-ui/core/Link";

export default function ElevateAppBar({title, children}) {
    const img = <img alt="Devonshire Gothic" style={{maxWidth: '100%', height: 'auto', paddingRight: '20px'}} src={DevTitle}/>;
    return (
        <React.Fragment>
            <CssBaseline />
                <AppBar className="app-bar">
                    <Toolbar className="tool-bar">
                        <Typography variant="h6">
                            {img}
                        </Typography>
                        <Link href="https://www.etsy.com/shop/DevonshireGothic"
                              color="inherit"
                              target="_blank"
                              className="shop-link">
                            Etsy Shop
                        </Link>
                    </Toolbar>
                </AppBar>
            <Toolbar />
            <Container>
                { children }
            </Container>
        </React.Fragment>
    );
}
