import React from 'react';
import './App.css';
import ProductCard from './components/ProductCard';
import Box from "@material-ui/core/Box";
import ElevationAppBar from "./components/ElevationAppBar";

function App() {
  return(<React.Fragment>
          <ElevationAppBar>
              <Box display="flex" flexWrap="wrap" justifyContent="center" className="item-box">
                  <ProductCard
                      className="product-card"
                      title='Islington Coat'
                      subText='Black/Black'
                      img={'thumbs/islington_black_black_fireplace.jpg'}
                      link='https://www.etsy.com/listing/875170861/islington-coat-blackblack-velvet-gothic'
                  />
                  <ProductCard
                      className="product-card"
                      title='Islington Coat'
                      subText='Black/Purple'
                      img={'thumbs/islington_black_purple_radiator.jpg'}
                      link='https://www.etsy.com/listing/861817540/islington-coat-blackpurple-velvet-gothic'
                  />
                  <ProductCard
                      className="product-card"
                      title='Islington Coat'
                      subText='Burgundy/Black'
                      img={'thumbs/islington_burgundy_black_sette.jpg'}
                      link='https://www.etsy.com/listing/861800194/islington-coat-burgundyblack-velvet'
                  />
                  <ProductCard
                      className="product-card"
                      title='Islington Coat'
                      subText='Black/Blue'
                      img={'thumbs/islington_black_blue_cabinet.jpg'}
                      link='https://www.etsy.com/listing/861810852/islington-coat-blackblue-velvet-gothic'
                  />
                  <ProductCard
                      className="product-card"
                      title='Tuna'
                      subText='Helper'
                      desc='This beautiful stripe nosed tortie cat came into our lives in 2014'
                      img={'thumbs/tuna_profile.jpg'}
                  />
                  <ProductCard
                      className="product-card"
                      title='Elise'
                      subText='Helper'
                      desc='This beautiful stripe beige chinned tortie cat came into our lives in 2014'
                      img={'thumbs/elise_profile.jpg'}
                  />
              </Box>
          </ElevationAppBar>
  </React.Fragment>);
}

export default App;
